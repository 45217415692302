<template>
      <v-card
        elevation="2"
        width="100%"
        class="my-5 px-4 "
      >
        <v-card-title class="my-1 justify-center body-1 text--primary" > {{cardText}} </v-card-title>

        <NaviTable
          ref="navichild"
          :isTop="true"
          :pToggle="toggle"
          :popularList="popularList"
        />


      </v-card>

</template>

<script>
const  NaviTable = () => import('@/components/NaviTable.vue');

  export default {
  components: { NaviTable },
    name: 'CardPopularNavi',
    props:["popularList","toggle"],
    data: () => ({
        menu1: false,
        dateText:null,
        date: "2022-01",
    }),

    computed:{
      cardText:function(){
        if(this.popularList.length!=0 && this.popularList){
          return "この期間の人気のナビゲーション";
        }else{
          return "この期間にナビは利用されていません"
        }
      }
    },

    // methods:{
    //   make(array){
    //     this.$refs.navichild.makeRanking(array)
    //   }
    // }


    
   
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>

